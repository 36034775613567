<template>
  <div>
    <b-table-simple bordered responsive hover>
      <b-thead>
        <b-tr>
          <b-th v-if="selected_mode"> Seleccionar </b-th>
          <b-th style="width: 20%">
            <div>Nombre del Grupo</div>
          </b-th>
          <b-th style="width: 40%">
            <div>Lider del Grupo</div>
          </b-th>
          <b-th style="width: 40%">
            <div>Miembros del Grupo</div>
          </b-th>
          <b-th v-if="allows_crud && newTestGroups.length > 0"> </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-if="newTestGroups.length > 0">
          <b-tr v-for="group in newTestGroups" :key="group.id + 'group'">
            <b-td v-if="selected_mode" class="text-center">
              <b-form-checkbox
                v-model="group.selected"
                @input="slotGroupsSelectionChanged"
                :disabled="
                  group.students.every((x) => usersNoAvailable.includes(x))
                "
              >
                <!-- :disabled="!isNaN(egress_profile_id)" -->
              </b-form-checkbox>
            </b-td>
            <b-td>
              <div class="text-left">{{ group.name }}</div>
            </b-td>
            <b-td>
              <div class="text-left">
                <span
                  :class="{
                    'unavialable-student': usersNoAvailable.includes(
                      group.leader
                    ),
                  }"
                  v-b-tooltip.v-secondary.noninteractive.hover.top="
                    usersNoAvailable.includes(group.leader)
                      ? `Este estudiante está en el Grupo: ''${groupName(
                          group.leader
                        )}''`
                      : ''
                  "
                >
                  {{ getUserName(group.leader) }}
                </span>
              </div>
            </b-td>
            <b-td>
              <div
                class="text-left"
                v-for="(group_student, index) in group.students"
                :key="index"
              >
                <!--vfor con los nombres de los estudiantes -->
                <span
                  :class="{
                    'unavialable-student':
                      usersNoAvailable.includes(group_student),
                  }"
                  v-b-tooltip.v-secondary.noninteractive.hover.top="
                    usersNoAvailable.includes(group_student)
                      ? `Este estudiante está en el Grupo ''${groupName(
                          group_student
                        )}''`
                      : ''
                  "
                >
                  {{ getUserName(group_student) }}
                </span>
              </div>
            </b-td>
            <b-td v-if="allows_crud" class="container-btn-group">
              <div class="container-btn-group-div">
                <b-button
                  variant="primary"
                  size="sm"
                  class="edit-btn p-1 mb-1"
                  v-if="allows_crud && Test.allow_group_creation"
                  v-b-tooltip.top.noninteractive.v-secondary="'Editar Grupo'"
                  tabindex="-1"
                  @click="
                    $bvModal.show(
                      `modal-edit-group-${Test.id}-${container_instrument_id}-${group.id}`
                    )
                  "
                >
                  <b-icon-pencil-square></b-icon-pencil-square>
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  class="delete-btn p-1"
                  v-if="allows_crud"
                  tabindex="-1"
                  v-b-tooltip.top.noninteractive.v-secondary="'Eliminar Grupo'"
                  @click="askForDeleteGroup(group.id)"
                >
                  <b-icon-trash></b-icon-trash>
                </b-button>
              </div>
            </b-td>
            <b-modal
              :id="`modal-edit-group-${Test.id}-${container_instrument_id}-${group.id}`"
              title="Editar Grupo"
              size="lg"
              hide-footer
            >
              <GroupsForm
                :Test="Test"
                :StudentGroup="group"
                @updated="
                  $bvModal.hide(
                    `modal-edit-group-${Test.id}-${container_instrument_id}-${group.id}`
                  )
                "
                @deleted="
                  $bvModal.hide(
                    `modal-edit-group-${Test.id}-${container_instrument_id}-${group.id}`
                  )
                "
              ></GroupsForm>
            </b-modal>
          </b-tr>
        </template>
        <template v-else-if="newTestGroups.length == 0">
          <b-tr>
            <b-td colspan="3">
              <div>No hay ningún grupo ingresado</div>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "../../../../utils/utils";

export default {
  name: "GroupsTable",
  components: {
    GroupsForm: () => import("./GroupsForm"),
  },
  props: {
    Test: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    container_instrument_id: {
      type: String,
    },
    selected_mode: {
      default: false,
    },
    usersNoAvailable: {
      type: Array,
      default: () => [],
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    user_id: {
      type: Number,
      required: false,
    },
    //Este el el id del test del contenedor para indicar el grupo al cual pertenece el estudiante.
    id_test_container: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      new_tests: names.NEW_TESTS,
      new_test_groups: names.NEW_TEST_GROUPS,
    }),
    newTestGroups() {
      if ((this.evaluatee_view || this.evaluator_view) && this.user_id) {
        return this.new_test_groups.filter(
          (x) => x.test == this.Test.id && x.students.includes(this.user_id)
        );
      } else
        return this.new_test_groups
          .filter((x) => x.test == this.Test.id)
          .map((x) => ({ ...x, selected: false }));
    },
  },
  methods: {
    groupName(student_id) {
      if (!this.id_test_container) return "";
      const group_name = this.new_test_groups.find(
        (x) =>
          x.test == this.id_test_container && x.students.includes(student_id)
      );
      return group_name ? group_name.name : "";
    },
    getUserName(user_id) {
      const user = this.users.find((x) => x.id == user_id);
      if (user) {
        if (user.user_data)
          return (
            user.user_data.identifier +
            " " +
            user.first_name +
            " " +
            user.last_name
          );
        else return user.first_name + " " + user.last_name;
      } else return null;
    },
    askForDeleteGroup(group_id) {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar el Grupo?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_NEW_TEST_GROUP, group_id);
          toast(
            "Se eliminó el" +
              this.$getVisibleNames(
                "evaluations2.testdescriptor",
                false,
                "Descriptor"
              )
          );
        }
      });
    },
    slotGroupsSelectionChanged() {
      this.$emit(
        "update_checks",
        this.newTestGroups.filter((x) => x.selected)
      );
    },
  },
  created() {},
};
</script>

<style scoped>
.unavialable-student {
  text-decoration: line-through !important;
  color: #aaa;
}
.container-btn-group {
  vertical-align: middle;
}
.container-btn-group-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
th {
  background-color: var(--kl-menu-color) !important;
  color: white;
}
.select-form {
  min-width: 270px;
  font-size: var(--secondary-font-size);
  margin: 3px;
}
.delete-btn {
  border: 1px;
  max-height: 27px;
  width: 27px;
}
.delete-btn:hover {
  background-color: #e45d6b;
}
.edit-btn:hover {
  background-color: var(--kl-primary-button-hover-color);
}
.edit-btn {
  border: 1px;
  max-height: 27px;
  width: 27px;
}
</style>

