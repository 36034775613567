var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{attrs:{"bordered":"","responsive":"","hover":""}},[_c('b-thead',[_c('b-tr',[(_vm.selected_mode)?_c('b-th',[_vm._v(" Seleccionar ")]):_vm._e(),_c('b-th',{staticStyle:{"width":"20%"}},[_c('div',[_vm._v("Nombre del Grupo")])]),_c('b-th',{staticStyle:{"width":"40%"}},[_c('div',[_vm._v("Lider del Grupo")])]),_c('b-th',{staticStyle:{"width":"40%"}},[_c('div',[_vm._v("Miembros del Grupo")])]),(_vm.allows_crud && _vm.newTestGroups.length > 0)?_c('b-th'):_vm._e()],1)],1),_c('b-tbody',[(_vm.newTestGroups.length > 0)?_vm._l((_vm.newTestGroups),function(group){return _c('b-tr',{key:group.id + 'group'},[(_vm.selected_mode)?_c('b-td',{staticClass:"text-center"},[_c('b-form-checkbox',{attrs:{"disabled":group.students.every((x) => _vm.usersNoAvailable.includes(x))},on:{"input":_vm.slotGroupsSelectionChanged},model:{value:(group.selected),callback:function ($$v) {_vm.$set(group, "selected", $$v)},expression:"group.selected"}})],1):_vm._e(),_c('b-td',[_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(group.name))])]),_c('b-td',[_c('div',{staticClass:"text-left"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover.top",value:(
                  _vm.usersNoAvailable.includes(group.leader)
                    ? `Este estudiante está en el Grupo: ''${_vm.groupName(
                        group.leader
                      )}''`
                    : ''
                ),expression:"\n                  usersNoAvailable.includes(group.leader)\n                    ? `Este estudiante está en el Grupo: ''${groupName(\n                        group.leader\n                      )}''`\n                    : ''\n                ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true,"top":true}}],class:{
                  'unavialable-student': _vm.usersNoAvailable.includes(
                    group.leader
                  ),
                }},[_vm._v(" "+_vm._s(_vm.getUserName(group.leader))+" ")])])]),_c('b-td',_vm._l((group.students),function(group_student,index){return _c('div',{key:index,staticClass:"text-left"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive.hover.top",value:(
                  _vm.usersNoAvailable.includes(group_student)
                    ? `Este estudiante está en el Grupo ''${_vm.groupName(
                        group_student
                      )}''`
                    : ''
                ),expression:"\n                  usersNoAvailable.includes(group_student)\n                    ? `Este estudiante está en el Grupo ''${groupName(\n                        group_student\n                      )}''`\n                    : ''\n                ",modifiers:{"v-secondary":true,"noninteractive":true,"hover":true,"top":true}}],class:{
                  'unavialable-student':
                    _vm.usersNoAvailable.includes(group_student),
                }},[_vm._v(" "+_vm._s(_vm.getUserName(group_student))+" ")])])}),0),(_vm.allows_crud)?_c('b-td',{staticClass:"container-btn-group"},[_c('div',{staticClass:"container-btn-group-div"},[(_vm.allows_crud && _vm.Test.allow_group_creation)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:('Editar Grupo'),expression:"'Editar Grupo'",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"edit-btn p-1 mb-1",attrs:{"variant":"primary","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.$bvModal.show(
                    `modal-edit-group-${_vm.Test.id}-${_vm.container_instrument_id}-${group.id}`
                  )}}},[_c('b-icon-pencil-square')],1):_vm._e(),(_vm.allows_crud)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:('Eliminar Grupo'),expression:"'Eliminar Grupo'",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"delete-btn p-1",attrs:{"variant":"danger","size":"sm","tabindex":"-1"},on:{"click":function($event){return _vm.askForDeleteGroup(group.id)}}},[_c('b-icon-trash')],1):_vm._e()],1)]):_vm._e(),_c('b-modal',{attrs:{"id":`modal-edit-group-${_vm.Test.id}-${_vm.container_instrument_id}-${group.id}`,"title":"Editar Grupo","size":"lg","hide-footer":""}},[_c('GroupsForm',{attrs:{"Test":_vm.Test,"StudentGroup":group},on:{"updated":function($event){return _vm.$bvModal.hide(
                  `modal-edit-group-${_vm.Test.id}-${_vm.container_instrument_id}-${group.id}`
                )},"deleted":function($event){return _vm.$bvModal.hide(
                  `modal-edit-group-${_vm.Test.id}-${_vm.container_instrument_id}-${group.id}`
                )}}})],1)],1)}):(_vm.newTestGroups.length == 0)?[_c('b-tr',[_c('b-td',{attrs:{"colspan":"3"}},[_c('div',[_vm._v("No hay ningún grupo ingresado")])])],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }